@each $color, $value in $theme-colors {
  .dotted-before-#{$color} {
    &:before {
      content: "";
      display: inline-block;
      width: 0.6em;
      height: 0.6em;
      background-color: $value;
      vertical-align: middle;
      border-radius: 1em;
      margin-right: 0.4em;
    }
  }

  .dotted-after-#{$color} {
    &:after {
      content: "";
      display: inline-block;
      width: 0.6em;
      height: 0.6em;
      background-color: $value;
      vertical-align: middle;
      border-radius: 1em;
      margin-left: 0.4em;
    }
  }
}
