/* File Input
   ========================================================================== */

.file-field {
  position: relative;

  .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px;
  }

  input.file-path {
    width: 100%;
  }

  .btn {
    float: left;
    height: $input-height;
    line-height: $input-height;
  }

  span {
    cursor: pointer;
  }

  input[type="file"] {
    // Needed to override webkit button
    &::-webkit-file-upload-button {
      display: none;
    }

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
