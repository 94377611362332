html,
body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 1rem;
  #root {
    display: flex;
    flex-direction: column;
    flex: 0 0 100vw;
    min-height: 100vh;

    & > * {
      width: 100%;
      max-width: 100vw;
    }
  }

  .hiddendiv {
    visibility: hidden !important;
    display: none !important;
  }
}

@media print {
  html,
  body {
    width: 100%;
    min-height: 100vh;

    #root {
      display: block;
      flex-direction: unset;
      flex: unset;
      min-height: 100vh;

      & > * {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
