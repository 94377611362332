@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,500,700,900&display=swap");
@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "reboot";
@import "text-shadow";
@import "capsule-buttons";
@import "dotted";

#root {
  @media (prefers-color-scheme: dark) {
    background-color: #202528;
  }

  @media (prefers-color-scheme: light) {
    background-color: #e5e5e5;
  }
}

.fallback-spinner {
  display: grid;
  place-items: center;
  height: 100vh;

  // default color scheme &.dark &.light will be used if there is preferred theme localStorage.getItem("theme")
  @media (prefers-color-scheme: dark) {
    background-color: #202528;
  }

  @media (prefers-color-scheme: light) {
    background-color: #e5e5e5;
  }

  &.dark {
    background-color: #202528;
  }

  &.light {
    background-color: #e5e5e5;
  }

  .spinner-border {
    color: $primary;
  }
}

.main {
  min-height: 100vh;
  min-width: 100vw;
  &.dark {
    background-color: #202528 !important;
  }
  &.light {
    background-color: #e5e5e5;
  }
}

// flex utility
.flex-occupy {
  flex: 1 0 auto;
}

// custom inputs
.form-input-send {
  font-size: 2em;
  border-bottom: 4px solid currentColor;
  display: flex;
  flex-direction: row;

  input {
    color: currentColor;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    flex: 1 0 auto;

    &:focus {
      outline: none;
    }

    &::placeholder,
    &:disabled,
    &:read-only {
      color: currentColor;
      opacity: 0.8;
    }
  }
  button {
    color: currentColor;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
}

// gradient backgrounds
@each $name, $value in $theme-linear-gradients {
  .bg-#{$name} {
    background: linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -webkit-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -moz-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -ms-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -o-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
  }
}

// gradient buttons
@each $name, $value in $theme-linear-gradients {
  .btn-#{$name} {
    color: map_get($value, "text-color");
    background: linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -webkit-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -moz-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -ms-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background: -o-linear-gradient(
      map_get($value, "direction"),
      map_get($value, "gradient")
    );
    background-clip: padding-box;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    border-color: rgba(0, 0, 0, 0);

    //border-color: $border;
    @include box-shadow($btn-box-shadow);

    @include hover {
      color: map_get($value, "text-color");
      //@include gradient-bg($hover-background);
      //border-color: $hover-border;
    }

    &:focus,
    &.focus {
      box-shadow: 0
        0
        0
        $btn-focus-width
        rgba(map_get($value, "base-color"), 0.5);
      // Avoid using mixin so we can pass custom focus shadow properly
      //@if $enable-shadows {
      //    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //} @else {
      //    box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //}
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);

      //color: color-yiq($background);
      //background-color: $background;
      //border-color: $border;
      //// Remove CSS gradients if they're enabled
      //@if $enable-gradients {
      //    background-image: none;
      //}
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      //color: color-yiq($active-background);
      //background-color: $active-background;
      //@if $enable-gradients {
      //    background-image: none; // Remove the gradient for the pressed/active state
      //}
      //border-color: $active-border;
      //
      //&:focus {
      //    // Avoid using mixin so we can pass custom focus shadow properly
      //    @if $enable-shadows and $btn-active-box-shadow != none {
      //        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //    } @else {
      //        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //    }
      //}
    }
  }
}
@each $name, $value in $theme-linear-gradients {
  .btn-outline-#{$name} {
    color: map_get($value, "text-outline-color");
    background: none;
    border-color: map_get($value, "text-outline-color");
    @include box-shadow($btn-box-shadow);

    @include hover {
      color: map_get($value, "text-color");
      background: linear-gradient(
        map_get($value, "direction"),
        map_get($value, "gradient")
      );
      background: -webkit-linear-gradient(
        map_get($value, "direction"),
        map_get($value, "gradient")
      );
      background: -moz-linear-gradient(
        map_get($value, "direction"),
        map_get($value, "gradient")
      );
      background: -ms-linear-gradient(
        map_get($value, "direction"),
        map_get($value, "gradient")
      );
      background: -o-linear-gradient(
        map_get($value, "direction"),
        map_get($value, "gradient")
      );

      background-clip: padding-box;
      -webkit-background-clip: padding;
      -moz-background-clip: padding;
      border-color: rgba(0, 0, 0, 0);

      //@include gradient-bg($hover-background);
      //border-color: $hover-border;
    }

    &:focus,
    &.focus {
      box-shadow: 0
        0
        0
        $btn-focus-width
        rgba(map_get($value, "base-color"), 0.5);
      // Avoid using mixin so we can pass custom focus shadow properly
      //@if $enable-shadows {
      //    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //} @else {
      //    box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //}
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);

      //color: color-yiq($background);
      //background-color: $background;
      //border-color: $border;
      //// Remove CSS gradients if they're enabled
      //@if $enable-gradients {
      //    background-image: none;
      //}
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      //color: color-yiq($active-background);
      //background-color: $active-background;
      //@if $enable-gradients {
      //    background-image: none; // Remove the gradient for the pressed/active state
      //}
      //border-color: $active-border;
      //
      //&:focus {
      //    // Avoid using mixin so we can pass custom focus shadow properly
      //    @if $enable-shadows and $btn-active-box-shadow != none {
      //        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //    } @else {
      //        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      //    }
      //}
    }
  }
}

// close-icon
.close-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  position: relative;

  $tmp_size: 0.1em;
  &:before,
  &:after {
    display: block;
    content: "";
    background-color: currentColor;
    position: absolute;
    border-radius: $tmp_size;
    transform: scale(1.25) rotate(45deg);
  }

  &:before {
    width: 100%;
    height: $tmp_size;
    top: (1em / 2) - ($tmp_size / 2);
  }
  &:after {
    width: $tmp_size;
    height: 100%;
    left: (1em / 2) - ($tmp_size / 2);
  }
}

// print grid
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through $grid-columns {
      @media print {
        .col-print#{$infix}-#{$i} {
          @include make-col($i, $grid-columns);
        }
      }
    }
  }
}

// print utils
/* Always insert a page break after the element */
.always-page-break-after {
  page-break-after: always;
}

/* Avoid page break after the element (if possible) */
.avoid-page-break-after {
  page-break-after: avoid;
}

/* Insert page breaks after the element so that the next page is formatted as a left page */
.left-page-break-after {
  page-break-after: left;
}

/* Insert page breaks after the element so that the next page is formatted as a right page */
.right-page-break-after {
  page-break-after: right;
}

/* Always insert a page break before the element */
.always-page-break-before {
  page-break-before: always;
}

/* Avoid page break before the element (if possible) */
.avoid-page-break-before {
  page-break-before: avoid;
}

/* Insert page breaks before the element so that the next page is formatted as a left page */
.left-page-break-before {
  page-break-before: left;
}

/* Insert page breaks before the element so that the next page is formatted as a right page */
.right-page-break-before {
  page-break-before: right;
}

/* Avoid page break inside the element (if possible) */
.avoid-page-break-inside {
  page-break-inside: avoid;
}

.btn {
  margin-right: 0.4rem;
}
