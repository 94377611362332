.text-shadow-sm {
  text-shadow: 0 0.125rem 0.25rem rgba(black, 0.7) !important;
}
.text-shadow {
  text-shadow: 0 0.5rem 1rem rgba(black, 0.8) !important;
}
.text-shadow-lg {
  text-shadow: 0 1rem 3rem rgba(black, 0.9) !important;
}
.text-shadow-none {
  text-shadow: none !important;
}
