$primary: #00dc8d !default;
$primary-color: #00dc8d !default;
$primary-color-light: #06e1d1 !default;
$primary-color-dark: #06e1d1 !default;
$primary-color-new: #00dc8d !default;

$secondary-color: #00dc8d !default;
$success-color: $primary-color !default;
$error-color: #f95430 !default;
$link-color: blue !default;

$font-family-base: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$theme-colors: (
  "accent-1": #90fffc,
  "accent-2": #00fffc,
  "accent-3": #51d0d4,
  "accent-4": #000d50,
  "accent-5": #11b4c7,
  "accent-6": #00dc8d,
  "accent-7": #2bd3f9,
  "accent-8": #00dc8d,
  "pill-1": #15edde,
  "pill-2": #ff8458,
  "pill-3": #ffb957,
  "background": rgb(41, 46, 50),
  "primary": #00dc8d,
  "secondary": rgba(152, 152, 159, 0.1),
  // usually used with buttons and border accents "secondary": #202528,
  // usually used with backgrounds "accent1": #98989f,
  // usually used with heading titles "section": #292e32,
  // section color "warning": #fdba0f,
  "danger": #f95430,
  "muted": rgba(152, 152, 159, 0.4),
  "dropdown": rgba(152, 152, 159, 1),
  // or disabled "border": rgba(152, 152, 159, 0.3)
);

//$custom-switch-width: 3rem;
//$custom-switch-indicator-size: 1.3rem;
//$custom-switch-indicator-border-radius: 5rem;
/*
.custom-switch {
  .custom-control-label:before {
    height: 1.5rem;
  }
}
*/

//$custom-control-indicator-bg: #00fffc;
//$custom-switch-width: 4rem;
//$custom-switch-height: 2rem;

$theme-linear-gradients: (
  "lingrad-1": (
    "direction": 90deg,
    "gradient": (
      #00dc8d 0%,
    ),
    "base-color": mix(rgb(2, 0, 42), rgb(86, 222, 230)),
    "text-color": white,
    "text-outline-color": #00dc8d 0%,
  ),
  "lingrad-2": (
    "direction": 90deg,
    "gradient": (
      #00dc8d 0%,
    ),
    "base-color": mix(#00dc8d, #00dc8d),
    "text-color": white,
    "text-outline-color": #00dc8d,
  ),
  "lingrad-3": (
    "direction": 90deg,
    "gradient": (
      #00dc8d 0%,
    ),
    "base-color": #00dc8d,
    "text-color": white,
    "text-outline-color": #00dc8d,
  ),
);

// customize header font size
$font-size-base: 0.8rem;
$h1-font-size: $font-size-base * 1.7;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$spacer: 0.8rem;
$grid-gutter-width: 18px;

$font-size-base: 0.8rem;
$h1-fontsize: $font-size-base * 1.7;
$h2-fontsize: $font-size-base * 1.5;
$h3-fontsize: $font-size-base * 1.3;
$h4-fontsize: $font-size-base * 1.2;
$h5-fontsize: $font-size-base * 1.1;
$h6-fontsize: $font-size-base;
